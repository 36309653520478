body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* tüm paginatorlerin hizalanması icin */
.MuiSelect-select{
  display: flex !important;
  align-items: center !important;
}
.MuiTablePagination-selectLabel{
  margin: 0 !important;
}
.MuiTablePagination-displayedRows{
  margin: 0 !important;
}
