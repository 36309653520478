/* Dark mode ana stil */
body.dark-mode {
    background-color: rgb(28, 39, 60);
}

/* Container arka planları */
body.dark-mode div.jss10,
body.dark-mode div[class*="makeStyles-colorBackground"],
body.dark-mode [class*="makeStyles-colorBackground"],
body.dark-mode .colorBackground {
    background-color: rgb(28, 39, 60) !important;
}
body.dark-mode .MuiAutocomplete-endAdornment .MuiSvgIcon-root {
    fill: #ffffff !important;
}

body.dark-mode .home-main .card {
    background-color: rgb(58, 71, 95) !important;
}

body.dark-mode .fatura-main-t-body {
    background-color: gray !important;
}

body.dark-mode .project-main {
    background-color: gray !important;
}
body.dark-mode .evenRow{
    background-color: #b5b4b4 !important;
}
.fatura-main th {
    color: black !important;
}

body.dark-mode .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #333333 !important;
    border-radius: 4px !important;
}

body.dark-mode .MuiDataGrid-virtualScrollerContent {
    background-color: #1c273c !important;
}

body.dark-mode .MuiDataGrid-footerContainer {
    background-color: #1c273c !important;
}

body.dark-mode .ant-select-selection-item {
    color: white !important;
    background-color: #333333 !important;
}

body.dark-mode .ant-select-tree .ant-select-tree-treenode {
    background-color: gray !important;
}

body.dark-mode .MuiDataGrid-overlay {
    color: white !important;
    z-index: 99 !important;
    background-color: transparent !important;
}

body.dark-mode .anticon svg {
    fill: white !important
}

body.dark-mode .MuiTablePagination-select {
    color: white !important;
}

body.dark-mode .home-main h6,
p,
h3 {
    color: white;
}

body.dark-mode .home-main p strong {
    color: white !important;
}

body.dark-mode .modal-content {
    background-color: rgb(58, 71, 95) !important;
}

body.dark-mode .modal-title {
    color: white !important;
}

body.dark-mode .MuiDataGrid-actionsCell .MuiSvgIcon-root {
    fill: #ffffff !important
}

body.dark-mode .MuiTablePagination-actions .MuiSvgIcon-root {
    fill: #ffffff !important;
}
body.dark-mode .MuiAutocomplete-tag{
    background-color: rgb(177, 176, 176) !important;
}
body.dark-mode .table-body-main{
    background-color: rgb(146, 144, 144) !important;
}
body.dark-mode .login-label{
    color: white !important;
}
body.dark-mode .projects-report-main tr th{
    background-color: #333333 !important;
    color: white !important
}
body.dark-mode .projects-report-main tr th button svg{
    color: white !important
}

body.dark-mode .projects-report-main-body tr:nth-child(even) {
    background-color: rgb(180, 180, 180) !important;
}

body.dark-mode .projects-report-main-body tr:nth-child(odd) {
    background-color: rgb(151, 151, 151)!important;
}

/* Input stilleri */
body.dark-mode input,
body.dark-mode .MuiOutlinedInput-root {
    border-color: #ffffff !important;
    color: #ffffff !important;
    background-color: #333333 !important;
}
body.dark-mode .input-bg-modal input{
    background-color: transparent !important;
}
body.dark-mode .actions-icon svg{
    fill: white !important;
}
body.dark-mode .MuiOutlinedInput-notchedOutline {
    border-color: #ffffff !important;
}
body.dark-mode .MuiDataGrid-cell--textLeft a{
    color: white !important;
}
/* Autocomplete stilleri */
body.dark-mode .MuiAutocomplete-root .MuiOutlinedInput-root {
    border-color: #ffffff !important;
}

/* Label stilleri */
body.dark-mode .MuiInputLabel-root {
    color: #ffffff !important;
    /* background-color: #333333 !important; */
}
body.dark-mode .table-head {
    color: #ffffff !important;
    background-color: #333333 !important;
}

body .home-main h6,
p,
h3 {
    color: black;
}

body.dark-mode .MuiTablePagination-selectLabel {
    color: white !important;
}

body.dark-mode .dropdown-profile .dropdown-menu {
    background-color: rgb(58, 71, 95) !important;
    border-radius: 4px;
}

body.dark-mode .dropdown-menu p {
    color: white !important;
}

/* body.dark-mode .dropdown-menu a:hover {
    color: white !important;
} */
body.dark-mode .karlilik-body{
    background-color: #b9b8b8 !important;
}
body.dark-mode .content-karlilik label {
    color: white !important;
}
body.dark-mode .dropdown-menu a svg {
    color: white !important;
    fill: white !important
}

body.dark-mode .dropdown-menu a {
    color: white !important;
}

body.dark-mode .profile-main .card {
    background-color: rgb(86 96 115) !important
}

body.dark-mode .profile-main .styledWrap {
    background-color: rgb(86 96 115) !important;
    border-radius: 4px;
}

body.dark-mode .profile-main .styledWrap svg {
    color: #ffffff87 !important
}

body.dark-mode .MuiTablePagination-displayedRows {
    color: white !important;
}

body.dark-mode .burget-main {
    background-color: #333333 !important;
}

/* Card stilleri */
body.dark-mode .MuiCard-root {
    background-color: rgb(28, 39, 60) !important;
}

/* TextField Control stilleri */
body.dark-mode [class*="makeStyles-TextFieldControl"] {
    background-color: rgb(58, 71, 95) !important;
    box-shadow: none !important;
}

/* DataGrid stilleri */
body.dark-mode .MuiDataGrid-root {
    color: #ffffff !important;
    border-color: #ffffff !important;
}

/* Form Control stilleri */
body.dark-mode .MuiFormControl-root .MuiInputBase-root {
    background-color: #333333;
}

/* Select stilleri */
body.dark-mode .MuiSelect-root {
    color: #ffffff !important;
    border-color: #ffffff !important;
}

body.dark-mode .MuiMenuItem-root {
    color: #ffffff !important;
    background-color: #333333 !important;
}

body.dark-mode .MuiMenuItem-root:hover {
    background-color: #444444 !important;
}

body.dark-mode .MuiSelect-icon {
    color: #ffffff !important;
}

/* DatePicker stilleri */
body.dark-mode .react-datepicker {
    background-color: #333333;
    border-color: #ffffff;
}

body.dark-mode .react-datepicker__header {
    background-color: #444444;
    border-color: #ffffff;
}

body.dark-mode .react-datepicker__current-month,
body.dark-mode .react-datepicker__day-name,
body.dark-mode .react-datepicker__day {
    color: #ffffff !important;
}

body.dark-mode .MuiPickersDay-root {
    color: white!important;
    background-color: transparent !important;
}

body.dark-mode .MuiCalendarPicker-root {
    background-color: #333333 !important;
}
body.dark-mode .css-1v994a0[aria-live="polite"] {
    color: #FFFFFF;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);  /* Optional: adds slight shadow for better readability */
  }
body.dark-mode .MuiCalendarPicker-root svg{
    fill: white !important;
}
body.dark-mode .MuiCalendarPicker-root span{
    color: white !important;
}
body.dark-mode .eye-button{
    background-color:#777272 !important;
    border: 1px solid #777272 !important;
}
body.dark-mode .eye-button svg{
    fill: white !important
}
body.dark-mode .MuiPickersCalendarHeader-label {
    color: black !important;
}
body.dark-mode .MuiFormHelperText-root.Mui-error{
    color: pink !important;
}
body.dark-mode .MuiPickersDay-today {
    color: white !important;
    border: 1px solid white !important;
}
body.dark-mode .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected{
    background-color: #4a90e2 !important;
}
body.dark-mode .react-datepicker__year .react-datepicker__year-text{
    color: white !important;
}
body.dark-mode .react-datepicker-year-header{
    color: white !important;
}
body.dark-mode .MuiPickersDay-daySelected {
    background-color: blue !important;
    color: white !important;
}
body.dark-mode .react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text{
    color: white !important;
}
body.dark-mode .css-ub1r1.Mui-selected{
    background-color: blue !important;
    color: white !important;
}
body.dark-mode .MuiPickersDay-root:hover {
    background-color: rgba(0, 0, 255, 0.1) !important;
}

body.dark-mode .MuiClock-pin,
body.dark-mode .MuiClockPointer-root {
    background-color: blue !important;
}

body.dark-mode .MuiClockPointer-thumb {
    border-color: blue !important;
    background-color: blue !important;
}

body.dark-mode .MuiPickersDay-weekDayLabel {
    color: blue !important;
}

body.dark-mode .MuiPickersCalendarHeader-switchViewButton {
    color: blue !important;
}

body.dark-mode .MuiPickersArrowSwitcher-button {
    color: blue !important;
}

body.dark-mode .MuiPickersPopper-paper {
    background-color: gray !important;
}

/* Button stilleri */
body.dark-mode .btn-light {
    background-color: #444444 !important;
    color: #ffffff !important;
    border-color: #ffffff !important;
}

body.dark-mode .btn-white {
    background-color: #333333 !important;
    color: #ffffff !important;
    border-color: #ffffff !important;
}

/* Tablo hücre stilleri */
body.dark-mode .MuiDataGrid-root .MuiDataGrid-cell {
    /* color: #ffffff ; */
    border-color: #555555 !important;
}

body.dark-mode .MuiDataGrid-root .MuiDataGrid-cell p {
    color: #ffffff !important;
}
body.dark-mode .MuiDataGrid-root .MuiDataGrid-cell .lightgreen-bg-color p{
    color: black !important;
}
.net-input input {
    width: 85px !important;
}

body.dark-mode .MuiDataGrid-columnHeaders {
    background-color: #444444 !important;
    color: #ffffff !important;
    border-color: #555555 !important;
}

/* Form label stilleri */
body.dark-mode .labelControl {
    color: #ffffff !important;
}

/* Autocomplete dropdown stilleri */
body.dark-mode .MuiAutocomplete-popper .MuiPaper-root {
    background-color: #333333 !important;
    color: #ffffff !important;
}

body.dark-mode .MuiAutocomplete-option {
    color: #ffffff !important;
}

body.dark-mode .MuiAutocomplete-option:hover {
    background-color: #444444 !important;
}

/* Input focus stilleri */
body.dark-mode .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #4a90e2 !important;
}

body.dark-mode .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #4a90e2 !important;
}

/* Form Control Label stilleri */
body.dark-mode .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
    color: #ffffff !important;
}

/* Typography stilleri */
body.dark-mode .MuiTypography-colorTextSecondary {
    color: #ffffff !important;
}

/* DataGrid ek stilleri */
body.dark-mode .css-uw2ren-MuiDataGrid-virtualScrollerRenderZone {
    background-color: #333333 !important;
}

body.dark-mode .css-1b34haf-MuiDataGrid-footerContainer {
    background-color: #333333 !important;
}

/* Pagination stilleri */
body.dark-mode .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar p {
    color: #ffffff !important;
}

body.dark-mode .css-194a1fa-MuiSelect-select-MuiInputBase-input {
    color: #ffffff !important;
}

/* Header stilleri */
body.dark-mode .makeStyles-header {
    color: #ffffff !important;
}

/* makeStyles-colorBackground-* stilleri */
body.dark-mode .makeStyles-colorBackground-* {
    background-color: rgb(28, 39, 60) !important;
}